import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import posthog from "posthog-js";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import mixpanel from "mixpanel-browser";

Sentry.init({
  dsn: "https://fa4773da0203d5a47a381f714f8a40a6@o4508046746255360.ingest.us.sentry.io/4508046746386432",
  tracesSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
  enabled: process.env.NODE_ENV === "production",
});

function PosthogInit() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      posthog.init("phc_jlNi6aHKyP6wl7ptFUJIeRS13IKtJdzkil5957t5Gck", {
        api_host: "https://us.i.posthog.com",
        person_profiles: "identified_only",
        capture_pageview: false,
      });
      ReactGA.initialize("G-4N0LB6QFYM");
      ReactPixel.init("428577620164788");
    }
  }, []);

  return null;
}

function MixpanelInit() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      mixpanel.init("39515145293a8eb72dec8ddb8f5337f7", {
        track_pageview: "url-with-path",
        record_sessions_percent: 100,
      });
    }
  }, []);

  return null;
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
      <PosthogInit />
      <MixpanelInit />
    </StrictMode>
  );
});
